export default {
    "template": "componentsCompany/cherkizovo/CompanySettings",
    "default_number_format": {
        "decimal_point": ",",
        "decimal_length": 2
    },
    "menu_template": "componentsCompany/cherkizovo/header/MenuComponent",
    "enums": {
        "back_integration_statuses": {
            "1": "Создана",
            "2": "Данные отправлены",
            "3": "Получен ID задачи",
            "4": "Получен статус задачи",
            "10": "Завершена",
            "20": "ОШИБКА"
        }
    }
}